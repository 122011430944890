import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const RSVPPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=rsvp_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>RSVP</h1>
                    <p>Svar på om dere har anledning til å komme i bryllupet sendes på e-post til enten Nina (<a href={"mailto:ninagamstboeysen@gmail.com"}>ninagamstboeysen@gmail.com</a>)
                        eller Knut (<a href={"mailto:post@knutreistad.com"}>post@knutreistad.com</a>){/* <strong>før 1. april</strong>*/}. Alternativt kontakt Knut på telefon <nobr>+47 970 82 270</nobr>.</p>

                    <p>
                        Husk å oppgi følgende:
                    </p>

                    <ol style={{textAlign:"left"}}>
                        <li>Hvem som kommer</li>
                        <li>Om dere også planlegger å delta på den sosiale samlingen kvelden før bryllupet</li>
                        <li>Om det er allergier eller andre spesielle hensyn vi må ta</li>
                    </ol>

                    <p>
                        Vet dere allerede nå at dere ikke har anledning til å komme setter vi pris på om
                        dere sier i fra til oss.
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>RSVP</h1>

                    <p>Please reply to the RSVP by email to either Nina (<a href={"mailto:ninagamstboeysen@gmail.com"}>ninagamstboeysen@gmail.com</a>)
                        or Knut (<a href={"mailto:post@knutreistad.com"}>post@knutreistad.com</a>){/* <strong>before the 1st of April</strong>*/}.
                        If more convenient you can also reply to Knut via phone (<nobr>+47 970 82 270</nobr> or <nobr>+30 698 82 55 280</nobr>).</p>

                    <p>
                        In the reply kindly remember to specify:
                    </p>
                    <ol style={{textAlign:"left"}}>
                        <li>Who will be attending</li>
                        <li>Whether you plan to attend the pre-wedding event on the 10th of July</li>
                        <li>If there are allergies or other considerations we need to take into account</li>
                    </ol>


                    <p>
                        If you already now know that you will not be able to make it, we appreciate if you would take the time to notify us.
                    </p>
                </>
            }

        </main>
    )
}

export default RSVPPage
